import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging';
import { AdminApiFactory, AppApiFactory } from './services/AppFactory';

const appMessageService = AppApiFactory.message;
const adminMessageService = AdminApiFactory.message;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const firebase = initializeApp(firebaseConfig)
const messaging = getMessaging(firebase);
const isSupport = () => {
  return 'Notification' in window &&
         'serviceWorker' in navigator &&
         'PushManager' in window;
};
if (isSupport()) {
if (Notification.permission === 'granted') {
  getToken(messaging, { vapidKey: process.env.VUE_APP_MESSAGE_PUB_KEY as string })
    .then((token) => {
      if (token) {
        console.log('Token >>>', token);
        // save token to server here
        if (localStorage.getItem(process.env.VUE_APP_APP_KEY as string)) {
          appMessageService.saveToken(token);
        }
        if (localStorage.getItem(process.env.VUE_APP_ADMIN_KEY as string)) {
          adminMessageService.saveToken(token);
        }
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((error) => {
      console.error('An error occurred while retrieving token:', error);
    });
} else {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      getToken(messaging, { vapidKey: process.env.VUE_APP_MESSAGE_PUB_KEY as string })
        .then((token) => {
          console.log('Token after permission granted >>>', token);
          if (localStorage.getItem(process.env.VUE_APP_APP_KEY as string)) {
            appMessageService.saveToken(token);
          }
          if (localStorage.getItem(process.env.VUE_APP_ADMIN_KEY as string)) {
            adminMessageService.saveToken(token);
          }
        })
        .catch(error => {
          console.error('An error occurred while retrieving token after permission:', error);
        });
    } else {
      console.log('Notification permission not granted.');
    }
  });
}
}

export default messaging;

